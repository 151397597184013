import React, { useContext } from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import ArticlesComponent from "../components/articles"
import LanguageContext from "../wrappers/contexts/LanguageContext";
import "../assets/css/main.css"
import logo from "../images/logoBalearic.svg"

export default () => {
  return (<Layout seo={{ title: "Inicio" }}>
    <AllCategories/>
  </Layout>)
};

const AllCategories = () => {
  const { getTranslate } = useContext(LanguageContext)
  return (
    <StaticQuery
      query={query}
      render={data => (
        <div className="uk-section">
          <div className="uk-container uk-container-large uk-flex uk-flex-center">
            <a href="https://balearicapartments-rentals.com/">
              <img src={logo} style={{ height: "90px" }} alt=""/>
            </a>
          </div>
          <div className="uk-container uk-container-large uk-margin-large-top">
            <div className="uk-flex uk-flex-wrap uk-flex-center uk-text-center">
              <h1 className="title-category">{getTranslate("Título")}</h1>
            </div>
            <ArticlesComponent articles={data.allStrapiArticle.edges}/>
          </div>
        </div>
      )}
    />
  )
};

const query = graphql`
  {
    allStrapiArticle {
      edges {
        node {
          category {
            name {
              translate {
                text
                language {
                  lang
                }
              }
            }
          }
          image {
            publicURL
          }
          title {
            translate {
              language {
                lang
              }
              text
            }
          }
          id
          strapiId
          published_at
        }
      }
    }
  }
`;
