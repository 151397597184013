import React, { useContext } from "react"
import { Link } from "gatsby"
import moment from "moment";
import 'moment/locale/es';
import LanguageContext from "../wrappers/contexts/LanguageContext";

moment.locale("es");

const Card = ({ article, className = "" }) => {
  const { getTranslate } = useContext(LanguageContext);
  return (
    <Link to={`/article/${article.node.strapiId}`} className={"uk-link-reset " + className} style={{opacity: 0}}>
      <div className="uk-card uk-card-default uk-card-small uk-card-hover">
        <div className="uk-card-media-top">
          <img src={article.node.image.publicURL}
               alt={article.node.image.publicURL}
               height="100"
          />
        </div>
        <div className="uk-card-badge uk-label">{getTranslate(article.node.category.name.translate)}</div>
        <div className="uk-card-body">
          <h3 className="uk-card-title uk-text-large">{getTranslate(article.node.title.translate)}</h3>
        </div>
      </div>
    </Link>
  )
};

export default Card
